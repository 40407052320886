<template>
  <div class="rounded-md pt-5 px-4 w-full bg-white shadow">
    <div>
      <p class="sm:text-2xl text-xl font-semibold leading-6 text-blue-600  text-center ">
        {{ titleChart }}
      </p>
      <p v-if="headingChart" class="text-xs leading-3 text-gray-500 mt-1  text-center ">
        {{ headingChart }}
      </p>
    </div>
    <div class="items-center py-4 px-4">
      <canvas :id="chartId" height="100%" width="100%"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from 'chart.js'

export default {
  name: 'ChartPie',
  props: {
    chartId: String,
    titleChart: String,
    headingChart: String,
    chartData: Object,
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.createChart(this.chartId, this.chartData)
    })
  },

  methods: {
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId).getContext('2d')
      new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      })
    },
  },
}
</script>
