<template>
  <ButtonListMapKanban></ButtonListMapKanban>
</template>

<script>
import ButtonListMapKanban from './ButtonListMapKanban'

export default {
  name: 'MultipleListCreate',
  components: {
    ButtonListMapKanban,
  },
  data: function () {
    return {}
  },
}
</script>
