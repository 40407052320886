<template>
  <div class="w-full rounded-lg bg-white overflow-hidden shadow">
    <div class="w-full p-5">
      <p
        class="sm:text-2xl text-xl font-semibold leading-6 text-blue-600 text-center"
      >
        {{ titleChart }}
      </p>
      <p v-if="headingChart" class="text-xs text-center text-gray-500 mt-1">
        {{ headingChart }}
      </p>
      <canvas class="mt-9" :id="chartId"></canvas>
    </div>
  </div>
</template>

<script>
import { Chart } from 'chart.js'

export default {
  name: 'BarChart',
  props: {
    chartId: String,
    titleChart: String,
    headingChart: String,
    chartData: Object,
  },
  mounted() {
    this.createChart(this.chartId, this.chartData)
  },
  methods: {
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId)
      new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      })
    },
  },
}
</script>
