<template>
  <main>
    <router-link
      :to="{ path: '/posts', query: params }"
      class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <iconList class="-ml-0.5 mr-2 h-4 w-4" />
      {{ $t('post.page.choiceviewlistposts.listview') }}
    </router-link>

    <router-link
      :to="{ path: '/kanban', query: params }"
      class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <iconKanban class="-ml-0.5 mr-2 h-4 w-4" />
      {{ $t('post.page.choiceviewlistposts.kanbanview') }}
    </router-link>

    <router-link
      :to="{ path: '/map', query: params }"
      class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <iconGlobe class="-ml-0.5 mr-2 h-4 w-4" />
      {{ $t('post.page.choiceviewlistposts.cardview') }}
    </router-link>

    <router-link
      to="/statistiques"
      class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <iconChart class="-ml-0.5 mr-2 h-4 w-4" />
      {{ $t('post.page.choiceviewlistposts.statisticsview') }}
    </router-link>

    <router-link
      to="/performances"
      class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <iconProgress class="-ml-0.5 mr-2 h-4 w-4" />
      {{ $t('post.page.choiceviewlistposts.performanceview') }}
    </router-link>
  </main>
</template>

<script>
import iconList from '@/components/icons/iconList'
import iconKanban from '@/components/icons/iconKanban'
import iconGlobe from '@/components/icons/iconGlobe'
import iconChart from '@/components/icons/iconChart'
import iconProgress from '@/components/icons/iconProgress'

export default {
  name: 'ButtonListMapKanban',
  components: {
    iconList,
    iconKanban,
    iconGlobe,
    iconChart,
    iconProgress,
  },
  data: function () {
    return {}
  },
  computed: {
    params() {
      return this.$route.query ? this.$route.query : {}
    },
  },
}
</script>

<style>
.btn-group i {
  color: white;
}
</style>
