import axios from '@/config/backend'

export default class DashboardService {
    getDashboard(defaultDashboard) {
        return new Promise(function (resolve, reject) {
            axios
                .get(
                    '/api/' +
                        JSON.parse(localStorage.getItem('company')).id +
                        '/modules/' +
                        defaultDashboard
                )
                .then(({ data }) => resolve(data.data))
                .catch((e) => reject(e))
        })
    }
}
