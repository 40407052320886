<template>
    <div class="rounded-md pt-5 px-4 w-full bg-white shadow">
        <div class="items-center py-4 px-4">
            <iframe
                :src="`${metabaseUrl}/${type}/${uuid}?company_id=${companyUuid}`"
                frameborder="0"
                width="100%"
                height="500"
                allowtransparency
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChartPie',
    props: {
        uuid: String,
        type: String
    },
    data() {
        return {
            companyUuid: '',
            metabaseUrl: ''
        }
    },
    mounted() {
        this.companyUuid = JSON.parse(localStorage.getItem('company')).uuid
        this.metabaseUrl = process.env.VUE_APP_METABASE_PUBLIC_URL
    },

    methods: {}
}
</script>
